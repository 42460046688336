@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.topicspath {
  width: 100%;
  padding: 0 20px;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 40px); }
  @media screen and (max-width: 768px) {
    .topicspath {
      display: none; } }
  .topicspath li {
    display: inline-block;
    font-size: 16px; }
    @media screen and (max-width: 768px) {
      .topicspath li {
        font-size: 0.7em; } }
    .topicspath li:after {
      content: "\f105";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      display: inline-block;
      margin-left: 8px; }
    .topicspath li:last-child:after {
      content: none; }
    .topicspath li:last-child {
      color: #D71E1E; }
  .topicspath li, .topicspath a {
    color: #000; }

.lowerCaption {
  position: relative;
  width: 100%;
  background-image: url(http://placeholder.pics/svg/1200x300/FF6B6B);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-x: hidden;
  padding: 215px 0; }
  @media screen and (max-width: 768px) {
    .lowerCaption {
      padding: 150px 0; } }
  @media screen and (max-width: 568px) {
    .lowerCaption {
      padding: 120px 0; } }
  #company .lowerCaption {
    background-image: url(http://placeholder.pics/svg/1200x300/30FF64); }
  .lowerCaption_title {
    color: #000;
    font-size: 46px;
    max-width: 1165px;
    text-align: center;
    margin: 0 auto;
    padding: 0 10px;
    width: 100%; }
    @media screen and (max-width: 768px) {
      .lowerCaption_title {
        font-size: 30px;
        width: 100%;
        padding: 0 20px; } }
    @media screen and (max-width: 568px) {
      .lowerCaption_title {
        font-size: 24px; } }
